import * as React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
// markup
const NotFoundPage = () => {
  return (
    <Layout>
            <SEO 
                title="404 Error"
                description="Page not found"
              />
            <br /><br /><br />

          <div className="container center">
            <h1 className="center">Page not found</h1>
            <Link className="btn btn-slider z-depth-4-hover br-3" to="/" >Home</Link>
            <br /><br />
            <br /><br />
          </div>
        </Layout>
  )
}

export default NotFoundPage
